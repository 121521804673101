button {
  border-radius: 2px;
  font-size: inherit;
  padding: 3px 15px;
  text-align: center;
  font-family: "Amazon Ember";
  border-style: solid;
  border-color: var(--secondary-blue);
  border-width: 1px;
}

.light-button {
  background-color: var(--white);
  color: var(--secondary-blue);
}

.dark-button {
  background-color: var(--secondary-blue);
  color: var(--white);
}

button:hover {
  background-color: var(--tertiary-blue);
  color: var(--white);
  border-color: var(--tertiary-blue);
}

.info-banner-background {
  background-color: var(--light-gray);
  border-radius: 1.25rem;
  width: 70%;
  padding: 1% 1.25% 0.5% 1%;
  height: max-content;
}

.info-banner-header {
  color: var(--primary-blue);
  font-family: "Amazon Ember";
  font-size: 20px;
  margin-bottom: 20px;
  margin-left: 3px;
}

.info-banner-card {
  width: 95%;
  border-left: 9px var(--red);
  border-style: solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  margin: 6px;
}

.info-banner-card-colorless {
  width: 95%;
  border-left: 9px var(--white);
  border-style: solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  margin: 6px;
}

.info-banner-card-content {
  font-size: 17px;
  padding: 10px;
  margin-left: 1.5%;
  font-family: "Amazon Ember";
  font-weight: normal;
  color: black;
}

.card-links-container {
  color: #474747;
}

.tpta-accordion-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 10px;
}

.new-intake-card-header-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.new-intake-card-text {
  color: var(--gray);
  font-family: "Amazon Ember";
  width: 75%;
}

.project-name-link {
  color: var(--secondary-blue);
}

.questionnaire-name {
  color: var(--gray);
}

.recent-activities-container {
  display: flex;
}

.recent-activities {
  display: block;
  font-style: italic;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.tpta-card {
  position: relative;
  width: 100%;
  min-width: 300px;
  min-height: 115px;
  margin: 5px 0;
}

.intake-tpta-card {
  padding-right: 15px;
}

.text-icon {
  display: flex;
  justify-content: flex-start;
}

.text-icon > svg {
  fill: var(--link-blue);
}

.text-icon > svg:hover {
  cursor: pointer;
}

.view-link {
  position: absolute;
  top: 10px;
  right: 30px;
  text-transform: capitalize;
  color: var(--primary-blue);
}

.vendor-name-link {
  font-size: 0.5rem;
  color: var(--secondary-blue);
}

.card-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: "Amazon Ember";
  border-radius: 20px;
  height: 25px;
  width: 150px;
}

.assessor-label {
  background-color: var(--assessor-orange);
  color: #000;
}

.requestor-label {
  background-color: var(--requestor-green);
  color: #000;
}

.vendor-label {
  background-color: var(--vendor-purple);
  color: #000;
}

.closed-label {
  background-color: #000;
  color: var(--white);
}

.take-action-card {
  width: 350px;
  height: 600px;
}

.take-action-card-content-container {
  font-family: "Amazon Ember";
  position: relative;
  height: 90%;
  width: 100%;
  padding-top: 10px;
}

.take-action-container {
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Amazon Ember";
  z-index: 99;
}

.take-action-inner-container {
  height: 100%;
  width: 100%;
}

.take-action-card-header > button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.take-action-button,
.take-action-inner-button {
  position: absolute;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 100;
  padding-top: 5px;
  top: 0;
}

.take-action-button {
  right: 0;
}

.take-action-inner-button {
  right: 9px;
}

.take-action-button > svg,
.take-action-inner-button > svg {
  fill: var(--primary-blue);
}

.take-action-card-header > button > svg {
  height: 25px;
  width: 25px;
}

.take-action-button > svg:hover,
.take-action-inner-button > svg:hover {
  fill: var(--link-blue);
}

.take-action-card-header > button:hover {
  color: var(--link-blue);
  background-color: unset;
  cursor: pointer;
}

.take-action-card-header > button:hover > svg {
  fill: var(--link-blue);
}

.take-action-inner-content-container {
  padding: 10px 0;
}

.updates-content-container,
.todo-list-container {
  position: relative;
  height: calc(100% - 20px);
  width: 100%;
  margin: auto;
  overflow-y: auto;
}

.update-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  border-left: 1px solid var(--gray);
  width: 90%;
  margin: auto;
}

.update-inner-content-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 280px;
}

.take-action-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.todo-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  width: 90%;
  margin: auto;
}

.todo-list-item-container.odd,
.take-action-update-container.odd {
  background-color: #e4f0ff;
}

.todo-list-item-container:hover,
.take-action-update-container:hover {
  background-color: #ffe1be;
}

.todo-list-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
}

.todo-list-content > p {
  margin: 0;
}

.todo-item-metadata {
  color: var(--gray);
}

.complete-assessment-page-container {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}

.complete-assessment-page-tooltip {
  overflow: hidden;
}

/* Grid properties */
.complete-assessment-page-table {
  display: grid;
  letter-spacing: 1px;
  font-size: 0.8rem;
  width: 70%;
  margin-top: 10px;
  margin-right: 50px;
  font-family: "Amazon Ember";
  border-bottom: 1px solid black;
}

.complete-assessment-page-table-row {
  display: grid;
  grid-template-columns: 15% 15% 15% 10% 15% 15% 15%;
}

/* Border properties */
.complete-assessment-page-table-row div {
  border-collapse: collapse;
  border: 1px solid black;
  border-left: 0;
  border-bottom: 0;
}

.complete-assessment-page-table-row div:first-child {
  border-left: 1px solid black;
}

/* Cell properties */
.complete-assessment-page-table-row p {
  margin: auto;
  word-wrap: break-word;
  width: 95%;
  padding: 5px 0;
}

/* Header specific properties */
.complete-assessment-page-table-row-header p {
  font-size: 1rem;
  text-align: left;
  background-color: var(--secondary-blue);
  color: var(--white);
}

.complete-assessment-page-table-row-header {
  background-color: var(--secondary-blue);
}

.complete-assessment-page-table-row:nth-child(even) {
  background-color: var(--light-gray);
}

.complete-assessment-page-table-row:not(:first-child):hover {
  background-color: var(--gray);
}

.complete-assessment-page-table-row:nth-child(even):hover {
  background-color: var(--gray);
}

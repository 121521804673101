.landing-page-header {
  display: flex;
  justify-content: left;
  align-items: center;
}

.swimlane-container {
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.swimlane-container-container {
  position: relative;
  padding-top: 50px;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.swimlane-container-header {
  font-family: "Amazon Ember";
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.swimlane-divider {
  background-color: var(--gray);
  height: 8px;
  width: 100%;
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 20px;
}

.badge-background {
  background-color: var(--red);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--tertiary-blue);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-blue);
}

.favorites-list {
  color: var(--tertiary-blue);
  display: flex;
  height: 30px;
  margin-left: 28px;
  font-family: "Amazon Ember";
}

.favorites-list:hover {
  color: var(--white);
}

.sidebar-router-link {
  text-decoration: inherit;
}

.tps-catalog {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

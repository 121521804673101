.top-navigation-bar-background {
  width: 100%;
  height: 64px;
  background-color: var(--primary-blue);
  border-bottom: 9px var(--secondary-blue);
  bottom: 0;
}

.logo {
  width: 110px;
  height: 38px;
  margin-right: 10px;
  padding-left: 23px;
  padding-top: 24px;
}

.header-icon-text-container {
  margin-left: 5px;
}

.header-icon-text {
  margin-left: 10px;
  font-size: 32px;
  padding: 0;
}

.nav-element {
  color: var(--gray);
  font-size: 16px;
  font-family: "Amazon Ember";
  margin-right: 50px;
  text-decoration: none;
}

.nav-element:hover {
  color: var(--secondary-blue);
}

.profile-thumbnail-styling {
  text-align: left;
  padding-top: 7px;
}

.user-name-thumbnail {
  font-size: 15px;
  color: var(--white);
  font-weight: 400;
}

.user-role-thumbnail {
  font-size: 13px;
  color: var(--gray);
}

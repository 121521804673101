.consult-page-table {
  display: grid;
  padding-top: 15px;
  padding-bottom: 50px;
}

.consult-page-table p {
  margin: 0;
}

.closed-consults {
  padding-bottom: 30px;
}

.open-consults,
.closed-consults {
  font-family: "Amazon Ember";
}

.consults-table {
  border-top: 1px solid var(--gray);
  padding-bottom: 30px;
}

/* Row specific classes */
.consult-page-table-row {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  height: 40px;
}

.consults-page-table-name-description {
  border-left: 1px solid var(--gray);
}

.consult-page-table-row:hover > .consults-page-table-name-description,
.consult-page-table-row:hover span {
  color: var(--link-blue);
}

.consult-page-table-row div[role="cell"] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.consult-page-table-row div[role="cell"],
.consult-page-table-row div[role="columnheader"] {
  border-right: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}

.consult-page-table-row div[role="cell"]:last-child {
  justify-content: center;
  padding-left: 0;
}

/* Header specific classes */
.consult-page-table-row-header {
  font-family: "Amazon Ember";
  color: var(--gray);
  height: 25px;
  padding-bottom: 30px;
}

.consult-page-table-row-header p {
  padding: 5px 0;
  width: 95%;
  margin: auto;
}

.consult-page-table-row-header div {
  display: block;
  border-top: 1px solid var(--gray);
  padding: 0;
}

.consult-page-table-row-header div:first-child {
  border-left: 1px solid var(--gray);
}

.consult-page-table-row-header:hover {
  color: var(--gray);
}

/* Inner table headers */
.consults-page-inner-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
}

/* Label colors */

.state-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: "Amazon Ember";
  border-radius: 20px;
  height: 25px;
  width: 150px;
}

.assessor-label {
  background-color: var(--assessor-orange);
  color: #000;
}

.requestor-label {
  background-color: var(--requestor-green);
  color: #000;
}

.vendor-label {
  background-color: var(--vendor-purple);
  color: #000;
}

.closed-label {
  background-color: #000;
  color: #fff;
}

:root {
  /* Base */
  --blue-black: #0e212d;
  --off-black: #2c3042;
  --dark-gray: #666;
  --gray: #c4c4c4;
  --light-gray: #f4f4f4;
  --white: #fff;

  /* Primary */
  --primary-blue: #132f52;
  --secondary-blue: #335669;
  --tertiary-blue: #6e90a3;

  /* Secondary */
  --requestor-green: #71d599;
  --assessor-orange: #e49b57;
  --vendor-purple: #a2a9ed;
  --red: #960404;
  --faded-red: #ef9494;
  --yellow: #e6be2c;
  --link-blue: #126de0;
  --dark-orange: #bc3e00;
  --mint-green: #1f8476;

  /* Nav bar measurements */
  --sidebar-width: 264px;
  --top-nav-height: 108px;
}

html {
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  height: var(--top-nav-height);
}

main {
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100vh - var(--top-nav-height));
  margin-top: var(--top-nav-height);
  width: calc(100vw - var(--sidebar-width));
  margin-left: var(--sidebar-width);
}

.main-content-container {
  position: relative;
  height: 95%;
  width: 95%;
  margin: auto;
}
